<template>
    <div class="home__container">
        <LoadingScreen v-if="isLoadingScreen"></LoadingScreen>
        <template v-else>
            <div class="home__request-timeout" v-if="isTimeOut">
                {{ getTranslatedText('Request timeout. Please check your network connection.') }}
            </div>
            <template v-else>
                <div class="home__logo">
                    <img src="~ThemePath/images/logo.png" />
                    <div class="home__version">
                        {{ handleTextAppVersion() }}
                    </div>
                </div>
                <div class="home__title">
                    {{ getTranslatedText('dashboard') }}
                </div>
                <div class="home__items__container">
                    <template v-if="!$store.state.isOnlyPaument">
                        <div v-if="$store.state.isShowSports" @click="$router.push('/sports')" class="home__item home__item__sport">
                            <img src="~ThemePath/images/sports.png" />
                            <div class="home__item__title">
                                {{ getTranslatedText('bitville-sports') }}
                            </div>
                        </div>
                        <div v-if="$store.state.isShow590" @click="onOpenLoto('/loto', 1)" class="home__item home__item__lotto__590">
                            <img src="~ThemePath/images/lotteries.png" />
                            <div class="home__item__title">
                                {{ getTranslatedText('Loto 5/90') }}
                            </div>
                        </div>
                        <div v-if="$store.state.isShow645" @click="onOpenLoto('/loto-645', 2)" class="home__item home__item__lotto__645__home">
                            <img src="~ThemePath/images/lotteries.png" />
                            <div class="home__item__title">
                                {{ getTranslatedText('Loto 6/45') }}
                            </div>
                        </div>
                        <div v-if="$store.state.isShow459" @click="onOpenLoto('/loto-459', 3)" class="home__item home__item__lotto__459">
                            <img src="~ThemePath/images/lotteries.png" />
                            <div class="home__item__title">
                                {{ getTranslatedText('loto 4/59') }}
                            </div>
                        </div>
                        <div v-if="$store.state.isShow545" @click="onOpenLoto('/loto-545', 4)" class="home__item home__item__lotto__545">
                            <img src="~ThemePath/images/lotteries.png" />
                            <div class="home__item__title">
                                {{ getTranslatedText('lotto 5 of 45') }}
                            </div>
                        </div>
                        <div v-if="$store.state.isShowInstantLotto" @click="onOpenLoto('/lotto-instant/numbers', 5)" class="home__item home__item__lotto__instant">
                            <img src="~ThemePath/images/lotteries.png" />
                            <div class="home__item__title">
                                {{ getTranslatedText(INSTANT_GAME_TITLE) }}
                            </div>
                        </div>
                    </template>
                    <div @click="onOpenLoto('/voucher')" class="home__item home__item__voucher">
                        <img src="~ThemePath/images/voucher.png" />
                        <div class="home__item__title">
                            {{ getTranslatedText('voucher lonaguiweb') }}
                        </div>
                    </div>
                    <div class="home__item home__item__history" @click="$router.push('/history')">
                        <img src="~ThemePath/images/report.png" />
                        <div class="home__item__title">
                            {{ getTranslatedText('HISTORY') }}
                        </div>
                    </div>
                    <div @click="$router.push('/scan-ticket')" class="home__item home__item__scan">
                        <img src="~ThemePath/images/scan.png" />
                        <div class="home__item__title">
                            {{ getTranslatedText('scan') }}
                        </div>
                    </div>
                    <div @click="$router.push('/report')" class="home__items">
                        <div class="home__items__item home__items__item__left">
                            <img src="~ThemePath/images/report.png" />
                            <div class="home__item__title">
                                {{ getTranslatedText('report') }}
                            </div>
                        </div>
                        <div @click="$router.push('/report-final')" class="home__items__item">
                            <img src="~ThemePath/images/report.png" />
                            <div class="home__item__title">
                                {{ getTranslatedText('report final') }}
                            </div>
                        </div>
                    </div>
                    <template v-if="$store.state.isShow645 || $store.state.isShow590">
                        <div class="home__items home__items__2">
                            <div @click="$router.push('/results')" class="home__items__item home__item__result">
                                <img src="~ThemePath/images/result.png" />
                                <div class="home__item__title">
                                    {{ getTranslatedText('result') }}
                                </div>
                            </div>
                            <div @click="$router.push('/promotion-results')" class="home__items__item home__item__promotion_result">
                                <img src="~ThemePath/images/result.png" />
                                <div class="home__item__title">
                                    {{ getTranslatedText('promotion results') }}
                                </div>
                            </div>
                        </div>
                        <div class="home__items home__items__2">
                            <div @click="$router.push('/about')" class="home__items__item home__items__item__left-2">
                                <img src="~ThemePath/images/about.png" />
                                <div class="home__item__title">
                                    {{ getTranslatedText('about') }}
                                </div>
                            </div>
                            <div @click="$router.push('/print')" class="home__items__item home__items__item__right-2">
                                <img src="~ThemePath/images/print.png" />
                                <div class="home__item__title">
                                    {{ getTranslatedText('print') }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else @click="$router.push('/print')" class="home__item home__item__print">
                        <img src="~ThemePath/images/print.png" />
                        <div class="home__item__title">
                            {{ getTranslatedText('print') }}
                        </div>
                    </div>
                    <div @click="$router.push('/print')" class="home__items home__items__3">
                        <div class="home__items__item">
                            <div @click="$router.push('/change-pin')" class="home__item__title">
                                {{ getTranslatedText('Change PIN') }}
                            </div>
                        </div>
                        <div class="home__items__item" @click="handleLogout">
                            <div class="home__item__title">
                                {{ getTranslatedText('logout') }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>
<script>
import languageMixins from '@/mixins/language/language-mixins'
import AuthService from '@/services/auth.service'
import settingService from '@/services/setting.service'
import LoadingScreen from '@/components/common/loading/index.vue'

import { version } from '../../../package.json'
import { isStrEmpty, setSettingSite } from '@/utils/utils'
import { INSTANT_GAME_TITLE, INSTANT_SLUG_GAME } from '@/constants/lotto'
const ACCOUNT = process.env.VUE_APP_ACCOUNT
const IS_DEBUG = process.env.VUE_APP_IS_DEBUG

export default {
    mixins: [languageMixins],
    components: { LoadingScreen },
    data() {
        return {
            appVersion: version,
            ACCOUNT: ACCOUNT,
            IS_DEBUG: IS_DEBUG,
            isLoadingScreen: true,
            isTimeOut: false,
            INSTANT_GAME_TITLE
        }
    },
    created() {
        this.fetchSetting()
    },
    methods: {
        async fetchSetting() {
            this.isLoadingScreen = true
            try {
                let setting = await settingService.fetchSetting()
                this.$store.state.isOnlyPayment = isStrEmpty(setting.guinee?.enabled_game_slugs)

                this.$store.state.isShow645 = setting.guinee?.enabled_game_slugs?.indexOf('lotto-6-45') !== -1
                this.$store.state.isShow590 = setting.guinee?.enabled_game_slugs?.indexOf('lotto-5-90') !== -1
                this.$store.state.isShow459 = setting.guinee?.enabled_game_slugs?.indexOf('loto-4-59') !== -1
                this.$store.state.isShow545 = setting.guinee?.enabled_game_slugs?.indexOf('lotto-5-45') !== -1
                this.$store.state.isShowInstantLotto = setting.guinee?.enabled_game_slugs?.indexOf(INSTANT_SLUG_GAME) !== -1

                this.$store.state.isShowSports = setting.guinee?.enabled_game_slugs?.indexOf('bitville-sports') !== -1
                this.$store.state.isShowVoucher = setting.guinee?.enabled_game_slugs?.indexOf('ludwin-prepaid-voucher') !== -1


                this.$store.state.reprintCountToShowCancel = setting.guinee?.reprint_count_to_show_cancel ? parseInt(setting.guinee?.reprint_count_to_show_cancel) : null
                this.$store.state.milisecondsToForceFinishPrinting = setting.guinee?.miliseconds_to_force_finish_printing ? parseInt(setting.guinee?.miliseconds_to_force_finish_printing) : null
                this.$store.state.networkTimeoutSeconds = setting.guinee?.network_timeout_seconds ? parseInt(setting.guinee?.network_timeout_seconds) : null

                const isShow645 = this.$store.state.isShow645
                const isShow590 = this.$store.state.isShow590
                const isShow459 = this.$store.state.isShow459
                const isShow545 = this.$store.state.isShow545
                const isShowInstantLotto = this.$store.state.isShowInstantLotto

                const filteredData = this.$store.state.cart.filter(
                    (x) => (isShow645 && x.gameType === 'lotto-6-45') 
                    || (isShow590 && x.gameType === 'loto-590') 
                    || (isShow459 && x.gameType === 'loto-4-59') 
                    || (isShow545 && x.gameType === 'lotto-5-45')
                    || (isShowInstantLotto && x.gameType === INSTANT_SLUG_GAME)
                )

                this.$store.state.quantity = filteredData.length

                setSettingSite(setting)
                this.isLoadingScreen = false
            } catch (error) {
                if (error === 'ECONNABORTED') {
                    this.isTimeOut = true
                }
                this.isLoadingScreen = false

                console.log(error)
            }
        },
        async handleLogout() {
            try {
                await AuthService.logout()
                this.$router.push('/login')
            } catch (error) {
                console.log(error)
            }
        },
        handleTextAppVersion() {
            return this.$t(`version ${this.appVersion}`)
        },
        onOpenLoto(name, number) {
            this.$store.state.stepCart = parseInt(number)
            this.$router.push(name)
        },
    },
}
</script>
<style lang="scss">
@import '~ThemePath/scss/home/_index.scss';
</style>
